import React from "react"
import Layout from "./Layout"
import userGroup3 from "../assets/images/user-group-3.svg"
import peersIcon from "../assets/images/peers-icon.svg"
import dataSource from "../data/region-data.json"
import {withPrefix} from 'gatsby'
import { navigate } from '@reach/router'

export default function RegionInfo(props) {
  let regionData = dataSource[props.region] || [];
  if(regionData.length === 0) {
    window.location.href = '/';
    return null;
  } 
  return (
    <Layout regionName={regionData.name} slug={props.region}>
      <div className="container">
        <h2 className="intro-section-title">
          The Importance of Managing Viral Suppression and Triple-Class
          Resistance in HIV-1
        </h2>
        <p className="homepage-content mb-30-40">
          Viral suppression through the use of antiretroviral therapy (ART) has
          markedly improved life expectancy among individuals living with human
          immunodeficiency virus 1 (HIV-1).<sup>1</sup> Yet, a significant proportion of people living with HIV-1 have viral loads that are not fully suppressed and may have developed resistance to ART, negatively impacting outcomes.<sup>2,3</sup> This resource
          helps provide greater insights on the management of viral suppression and triple-class resistance in HIV-1 in {regionData.name}.
        </p>
        <div className="state-image-wrapper text-center">
          <h3 className="state-image-title-nos">{regionData['patient_count_1']}</h3>
          <h3 className="state-image-title">
            HIV-1 Patients in Care in <span>{regionData.name}<sup>4,a</sup></span>{" "}
          </h3>
          <div className="state-image-container">
            <img src={withPrefix(regionData.map_image)} alt={`${regionData.name} State Map`}/>
          </div>
        </div>

        <div className="row user-group-wrapper text-center">
          <div className="col-sm-6">
            <h2 className="user-group-title"> 1-in-{regionData.patient_count_4} </h2>
            <div className="user-group-image-wrapper">
              <img 
                className="img-fluid" 
                src={withPrefix(regionData.patient_icon)} 
                alt={`1 in ${regionData.patient_count_4} Patient Icons`} 
              />
            </div>
            <h3 className="user-group-title-2 user-group-caption">
              Patients in Care With Unsuppressed{" "}
              <span>
                {" "}
                Viral Load<sup>4,b</sup>{" "}
              </span>
            </h3>

            <h2 className="user-group-title"> {regionData.patient_count_3} </h2>
            <h3 className="user-group-title-2">
              of Patients in Care With{" "}
              <span>
                {" "}
                Unsuppressed Viral Load<sup>4,b</sup>
              </span>
            </h3>
          </div>
          <div className="col-sm-6 user-group-3-col">
            <h2 className="user-group-title"> 1-in-3 </h2>
            <div className="user-group-image-wrapper user-group-3">
              <img className="img-fluid user-group-3-img" src={userGroup3} alt="1 in 3 Patient Icons" />
            </div>
            <h3 className="user-group-title-2 user-group-caption">
              Patients in Care With Some HIV-1{" "}
              <span>
                Drug Resistance<sup>5,c</sup>
              </span>
            </h3>

            <h2 className="user-group-title"> {regionData.patient_count_5} </h2>
            <h3 className="user-group-title-2">
              of Patients in Care With Triple-Class{" "}
              <span>
                {" "}
                Drug Resistance<sup>4,5,d</sup>{" "}
              </span>
            </h3>
          </div>
        </div>

        <div className="user-group-terms-text">
          <p>
            <sup>a</sup>Among persons aged ≥13 years with infection diagnosed
            by year-end 2017 and alive at year-end 2018 having received ≥1 CD4
            or viral load test.
          </p>
          <p>
            <sup>b</sup>Calculated based on HIV-1 viral suppression during 2018 among
            persons aged ≥13 years with infection diagnosed by year-end 2017 and
            alive at year-end 2018 having received ≥1 CD4 or viral load test, by
            area of residence.
          </p>
          <p>
            <sup>c</sup>Calculated by using the Conference on Retroviruses and Opportunistic
            Infections (CROI) presented national percentage (33%) of HIV-1
            patients with some HIV-1 drug resistance and converting to a reduced
            fraction.
          </p>
          <p>
            <sup>d</sup>Local prevalence of triple-class resistance (TCR) was estimated by
            extrapolating national TCR prevalence<sup>5</sup> to local HIV
            prevalence data.<sup>4</sup>
          </p>
        </div>
      </div>
      <div className="peers-wrapper">
        <div className="container">
          <h3 className="peers-section-title">
            {" "}
            Perspectives From Your Peers{" "}
          </h3>
          <div className="peers-row peers-row-1">
            <div className="peers-col-user">
              <img 
                src={withPrefix(regionData.kol_headshot)}
                alt={`${regionData.kol_name} Image`} 
              />
              <p>
                {regionData.kol_name} <br />
                {regionData.kol_title} <br />
                {regionData.kol_company} <br />
                {regionData.kol_city}, {regionData.kol_state}
              </p>
            </div>
            <div className="peers-col-pov">
              <h2> {regionData.selected_kol_pov} </h2>
            </div>
          </div>

          {/* <div className="peers-row">
            <div className="peers-col-user">
              <img src={peersIcon} alt="" />
              <p>
                Name <br />
                Title <br />
                Company <br />
                City, State
              </p>
            </div>
            <div className="peers-col-pov">
              <h2> Selected KOL POV </h2>
            </div>
          </div> */}
        </div>
      </div>

      <div className="reference-content">
        <div className="container">
          <p>
            <strong>REFERENCES: </strong>
            <strong>1.</strong> Samji H, Cescon A, Hogg RS, et al. Closing the
            gap: increases in life expectancy among treated HIV-positive
            individuals in the United States and Canada. <em>PLoS ONE.</em>{" "}
            2013;8(12):e81355. doi:10.1371/journal.pone.0081355. <strong>2.</strong> Li Z, Purcell DW, Sansom SL, Hayes D, Hall I.{" "}
            <em>Vital signs:</em> HIV transmission along the continuum of care —
            United States, 2016. <em>MMWR Morb Mortal Wkly Rep.</em>{" "}
            2019;68(11):267-272. <strong>3.</strong> Panel on Antiretroviral Guidelines for Adults
            and Adolescents.{" "}
            <em>
              Guidelines for the Use of Antiretroviral Agents in Adults and
              Adolescents with HIV.
            </em>{" "}
            Department of Health and Human Services.
            https://clinicalinfo.hiv.gov/sites/default/files/inline-files/AdultandAdolescentGL.pdf.
            Accessed May 29, 2020. <strong>4.</strong> Centers for Disease Control and Prevention.{" "}
            <em>
              {" "}
              Monitoring Selected National HIV Prevention and Care Objectives by
              Using HIV Surveillance Data—United States and 6 Dependent Areas,
              2018.
            </em>{" "}
            Centers for Disease Control and Prevention; 2020. Vol 25 (No. 2).
            HIV Surveillance Supplemental Report. Published May 2020.
            https://www.cdc.gov/hiv/pdf/library/reports/surveillance/cdc-hiv-surveillance-supplemental-report-vol-25-2.pdf.
            Accessed June 24, 2020. <strong>5.</strong> Vannappagari V, Henegar CE, Underwood M, Ragone
            L, Garges H. Trends and characteristics of HIV-1 drug resistance in
            the United States (2012-2018). Abstract presented at: Conference on
            Retroviruses and Opportunistic Infections; March 8-11, 2020; Boston,
            Massachusetts. Session P-I03.
          </p>
        </div>
      </div>
    </Layout>
  )
}
