import React from 'react'
import logo from "../assets/images/thera-technologies-logo.svg";

export default function Footer() {
    return (
        <div class="footer-wrapper">
            <div className="footer-logo-wrapper">
                <a href="https://www.theratech.com/en/" target="_new">
                    <img src={logo} alt="Thera Logo" />
                </a>
            </div>
            <div className="footer-copyright-wrapper">
                <div className="container">
                    <p className="copyright-line-1"> Brought to you as an educational service by <span> Theratechnologies Inc. </span> </p>
                    <p className="copyright-line-2"> Copyright &copy; 2020 Theratechnologies Inc. All rights reserved. 846-01-07/2020 </p>
                </div>
            </div>
            <div className="footer-navitation-wrapper">
                <div className="container">
                    <ul className="nav-list">
                        <li><a href="https://www.theratech.com/en/terms-of-use/" target="_new"> TERMS OF USE </a></li>
                        <li>|</li>
                        <li><a href="https://www.theratech.com/en/privacy-policy/" target="_new"> PRIVACY POLICY </a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
