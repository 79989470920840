import React, { useEffect } from 'react'
import { navigate, History, Redirect } from '@reach/router'
import $ from 'jquery'
import regionData from '../data/region-data.json'
import { withPrefix } from "gatsby"

export default function Header({ regionName, slug }) {
    useEffect(() => {
        let selectElem = $(".js-example-placeholder-single");

        //Force set select value to null so the placeholder can be reset
        $('select').val(null);

        selectElem.select2({
            placeholder: slug == undefined ? "SELECT STATE" : "CHANGE STATE",
            minimumResultsForSearch: Infinity
        });

        selectElem.on('select2:opening', function (event) {
            $('select').val(null);
        })

        selectElem.on('select2:select', function (e) {
            // navigate(withPrefix(`/${e.target.value}`));
            window.location.href = withPrefix(`/${e.target.value}`);
            // $('select').val(null);
        });
    })

    return (
        <div className="banner-wrapper">
            <div className="container">
                <h1> HIV-1, Viral Suppression, and Triple-Class <span> Resistance {regionName ? `in ${regionName}` : ''} </span></h1>
                <select className="js-example-placeholder-single js-states form-control" style={{visibility: 'hidden'}}>
                    <option></option>
                    {
                        Object.keys(regionData).map((region) => {
                            return (
                                <option key={region} value={region}>{regionData[region].name}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

