import React from 'react'
import Layout from './Layout'

export default function Homepage() {
    return (
        <Layout>
            <div className="container">
                <h2 className="intro-section-title"> What You Should Know About HIV-1 and Viral Suppression in Your Area </h2>
                <p className="homepage-content">
                    Antiretroviral therapy (ART) has enabled viral suppression, leading to markedly improved life expectancy among individuals living with human immunodeficiency virus 1 (HIV-1). Yet, a significant proportion of people living with HIV-1 have viral loads that are not fully suppressed and may have developed resistance to ART, negatively impacting outcomes. Select your state* from the drop-down menu above to learn more about HIV-1 and viral suppression in your area and gain insights from peers in your region about the importance of viral suppression in the management of HIV-1.
            </p>
                <p className="condition-content">*Data available only for states listed on the drop-down menu.</p>
            </div>
        </Layout>
    )
}
